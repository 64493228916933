import React from "react"
import starIcon from "../../assets/images/technical-support.png"
import MediumAIToolbox from "./MediumAIToolbox"
import BlogSidebarAIToolbox from "./BlogSidebarAIToolbox"

const MediumAIToolboxAll = (props) => {
  return (
    <section className="blog-area pt-100 pb-70">
      <div className="container">
        <div className="section-title">
          <span className="sub-title">
            <img src={starIcon} alt="blog" />
            AI Toolbox
          </span>
          <h2>Tutorials, guides, and case studies on using various AI tools and frameworks.</h2>
        </div>
        <div className="row">
          <div className="col-lg-8 col-md-12">
            <MediumAIToolbox limit={100} tag={props.tag} />
          </div>
          <div className="col-lg-4 col-md-12">
            <BlogSidebarAIToolbox />
          </div>
        </div>
        <div className="text-center">
                <a href="https://medium.com/ai-toolbox" className="default-btn" target="_blank" rel="noreferrer">
                  <i className="flaticon-view"></i>
                  To All Medium Articles
                  <span></span>
                </a>
              </div>
      </div>
    </section>
  )
}

export default MediumAIToolboxAll
