import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import MediumAIToolboxAll from '../components/BlogContent/MediumAIToolboxAll'

const AIToolbox = ({location}) => {

    const params = new URLSearchParams(location.search);
    const tag = params.get("tag");

    return (
        <Layout>
            <Navbar />
            <PageBanner
                pageTitle="AI Toolbox" 
                homePageText="Home" 
                homePageUrl="/" 
                activePageText="AI Toolbox" 
            />
            <MediumAIToolboxAll tag={tag} />
            <Footer />
        </Layout>
    );
}

export default AIToolbox;